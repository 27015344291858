module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"C:\\Users\\mmira\\Desktop\\gatsby_sites\\gatsby-koms/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.kyoms.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kentuckiana Oral Maxillofacial and Dental Implant Surgery","lang":"en","display":"standalone","short_name":"Kentuckiana Oral Maxillofacial and Dental Implant Surgery","description":"Dr. Nathan Walters, Dr. Christopher Noonan, Dr. Geoff Mills, Dr. Jamie Warren, and Dr. Will Allen at Kentuckiana Oral Maxillofacial and Dental Implant Surgery offer many oral surgery services including dental implants and wisdom teeth removal in Louisville and Mt. Washington, KY, and Jeffersonville, IN.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/favicon-512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0e8341ac70469f9896befd5fe6185377"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T8T8KVT","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
